const firebaseConfig = {
  apiKey: "AIzaSyBH46D-DSvt_tYrO_bd9m35KT5QtxOYeAs",
  authDomain: "my-website-tbeal.firebaseapp.com",
  databaseURL: "https://my-website-tbeal.firebaseio.com",
  projectId: "my-website-tbeal",
  storageBucket: "my-website-tbeal.appspot.com",
  messagingSenderId: "474003003331",
  appId: "1:474003003331:web:490243f726a2785b0b4f76",
  measurementId: "G-RLVBDWZ44Q"
};

export default firebaseConfig;
