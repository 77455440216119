export const Colors = {
  lime: "#95F14A",
  snackpass: "#00CEFF",
  black: "#000",
  sesame: "#202122",
  whitesmoke: "#F5F5F5",
  neo_blue10: "#daf5f0",
  neo_blue20: "#a7dbd8",
  neo_blue30: "#87ceeb",
  neo_blue40: "#69d2e7",
  neo_green10: "#b5d2ad",
  neo_green20: "#bafca2",
  neo_green30: "#90ee90",
  neo_green40: "#7fbc8c",
  neo_yellow10: "#fdfd96",
  neo_yellow20: "#ffdb58",
  neo_yellow30: "#f4d738",
  neo_yellow40: "#e3a018",
  neo_red10: "#f8d6b3",
  neo_red20: "#ffa07a",
  neo_red30: "#ff7a5c",
  neo_red40: "#ff6b6b",
  neo_pink10: "#fcdfff",
  neo_pink20: "#ffc0cb",
  neo_pink30: "#ffb2ef",
  neo_pink40: "#ff69b4",
  neo_purple10: "#e3dff2",
  neo_purple20: "#c4a1ff",
  neo_purple30: "#a388ee",
  neo_purple40: "#9723c9",
};
